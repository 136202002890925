<template>
    <div class="mug-upgrade-section">
        <div v-if="mug_options.length > 0">
            <div class="row">
                <div class="col">
                    <h3>GET A MUG WITH YOUR PORTRAIT</h3>
                </div>
            </div>
            <div class="row options">
                <div
                    class="col"
                    v-for="option in mug_options"
                    :key="option.label"
                >
                    <a
                        @click.prevent="selectOption(option)"
                        href="#"
                        class="option"
                        :class="{
                            selected: option.selected,
                            disabled: option.disabled,
                            'custom-mug': option.label === 'custom_mug',
                        }"
                    >
                        <p>
                            {{
                                capitalizeFirstLetter(
                                    snakeCaseToSpace(option.label)
                                )
                            }}
                        </p>
                        <p>
                            {{ formatPrice(option) }}
                        </p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useOrderStore } from "@/stores/order";
import { useCartStore } from "@/stores/cart";
import { snakeCaseToSpace, capitalizeFirstLetter } from "@/utilities/strings";
import { currencySymbol } from "@/utilities/currency";

export default {
    name: "MugUpgrade",
    props: {
        order: Object,
    },
    data() {
        return {
            mug_options: [],
            orderStore: useOrderStore(),
            cartStore: useCartStore(),
        };
    },
    mounted() {
        let availableMugUpgrades =
            this.orderStore.order_data.available_additional_upgrades.mug;

        if (availableMugUpgrades) {
            let mug_options = [];

            if (!this.order.mug) {
                mug_options.push({
                    current: true,
                    selected: true,
                    label: "No mug",
                    amount: 0,
                    currency: "USD",
                    is_void: true,
                });
            }

            for (let i = 0; i < availableMugUpgrades.length; i++) {
                let elm = Object.assign({}, availableMugUpgrades[i]);

                elm.current = false;
                elm.selected = false;
                elm.label = elm.mug;

                if (elm.mug === this.order.mug) {
                    elm.current = true;
                    elm.selected = true;
                }

                mug_options.push(elm);
            }

            if (mug_options.length > 1) {
                this.mug_options = mug_options;
            }
        }
    },
    unmounted() {
        this.cartStore.removeFromCart("mug");
        this.$emit("mugUpdate", 0);
    },
    methods: {
        snakeCaseToSpace,
        capitalizeFirstLetter,
        selectOption(option) {
            this.mug_options.forEach((elm) => {
                elm.selected = elm.label === option.label;

                if (elm.selected) {
                    if (elm.is_void) {
                        this.cartStore.removeFromCart("mug");
                    } else {
                        this.cartStore.addToCart(this.order, { mug: elm });
                    }

                    this.$emit("mugUpdate", elm.amount);
                }
            });
        },
        getUpgradeValue(option) {
            return option.amount;
        },
        formatPrice(option) {
            let value = this.getUpgradeValue(option);

            if (option.current) {
                return "Current";
            }

            return (
                "+" +
                currencySymbol(option.currency) +
                value * this.order.quantity
            );
        },
    },
};
</script>

<style scoped lang="scss">
h3 {
    margin: 16px 0 8px;
    font-size: 14px;
    line-height: 20px;
    color: #363636;
}

.col {
    padding-left: 6px;
    padding-right: 6px;
}

a.option {
    text-align: center;
    color: #392f5a;
    background-color: #ebeaee;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    display: block;
    padding: 8px 14px;
    outline: 1px solid #dad8e0;
    border-radius: 4px;

    &:hover {
        text-decoration: none;
    }

    &.selected {
        outline: 2px solid #392f5a;
    }

    &.disabled {
        cursor: default;
        background: lighten(#ebeaee, 10%);
        outline-color: lighten(#8d8b88, 2%);
        color: lighten(#9084b5, 2%);
    }

    &.custom-mug {
        background-image: url("@/assets/images/upsells/mug/custom_mug.png");
        background-position: right center;
        background-repeat: no-repeat;
        padding-right: 45px;
    }

    p {
        margin: 5px 0;
    }
}
</style>
