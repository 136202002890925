<template>
    <div class="images_slider_block">
        <div v-for="slide in slides" :key="slide">
            <expandable-image :src="slide" :frame="frame" />
            <div class="badges-wrapper">
                <img
                    src="@/assets/images/upsells/additional_copy/one_extra_copy_portrait.png"
                    alt="5 years warranty"
                    v-if="copy_purchased"
                    class="additional-copy-image"
                />
                <img
                    src="@/assets/images/upsells/warranty/5_years_warranty_portrait.png"
                    alt="5 years warranty"
                    v-if="warranty"
                    class="warranty-image"
                />
                <img
                    src="@/assets/images/upsells/shipping_upgrade/expedited_shipping_portrait.png"
                    alt="Expedited Shipping"
                    v-if="shipping_upgrade"
                    class="shipping-image"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ExpandableImage from "./ExpandableImage.vue";

//var ZoomComponent = Vue.component('zoom-on-hover', ZoomOnHover);
export default {
    name: "ImageSlider",
    components: { ExpandableImage },
    props: [
        "slides",
        "frame",
        "warranty",
        "copy_purchased",
        "shipping_upgrade",
    ],
};
</script>

<style lang="scss" scoped>
.images_slider_block {
    position: relative;
}

.badges-wrapper {
    position: absolute;
    text-align: center;
    width: 60px;
    top: 15%;
    right: -22px;

    img {
        margin: 5px;
        vertical-align: top;
        width: 50px;

        @media (min-width: 768px) {
            width: 60px;
        }
    }

    @media (min-width: 768px) {
        height: 100px;
        top: calc(100% - 43px);
        width: 100%;
        right: auto;
    }
}
</style>
