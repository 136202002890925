<template>
    <div class="line-item-upgrade-section">
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <h2 data-cy="product-title">{{ order.product }}</h2>
                        <p class="subtitle">
                            <span class="mobile-only">Current</span
                            ><span class="not-on-mobile">Current format</span>:
                            {{ order.orderSize
                            }}<span v-if="!order.itemIsDigital"
                                >, {{ currentFrame || "no frame" }}</span
                            ><span v-if="order.canvas"
                                >, {{ snakeCaseToSpace(order.canvas) }}</span
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="upgrade-controls"
            v-if="order.allowUpsells && order.egleStatus !== 'On Hold'"
            data-cy="upgrade-controls"
        >
            <div class="row">
                <div class="col">
                    <div
                        v-if="size_options.length > 1"
                        class="size-upgrade-section"
                    >
                        <div class="row">
                            <div class="col">
                                <h3>
                                    <span
                                        v-if="
                                            findCurrent('size_options')
                                                ?.size === 'digital only'
                                        "
                                    >
                                        UPGRADE TO PHYSICAL CANVAS
                                    </span>
                                    <span v-else>UPGRADE SIZE</span>
                                    <InfoPopoverComponent
                                        title="Upgrade to Physical Canvas"
                                        label="Compare"
                                    >
                                        <p>
                                            Turn your digital file into a
                                            stunning physical canvas! Enjoy the
                                            beauty of your artwork or photo in a
                                            tangible form, ready to hang and
                                            elevate your space instantly.
                                            Perfect for home décor or as a
                                            memorable gift.
                                        </p>
                                        <p>
                                            <br />
                                        </p>
                                        <p>
                                            <img
                                                src="@/assets/images/upsells/sizes/physical_canvas.jpg"
                                                alt="Upgrade to Physical Canvas"
                                                style="width: 100%"
                                            />
                                        </p>
                                    </InfoPopoverComponent>
                                </h3>
                            </div>
                        </div>
                        <div class="row options">
                            <div
                                class="col"
                                v-for="option in size_options"
                                :key="option.label"
                            >
                                <a
                                    @click.prevent="
                                        selectOption('size_options', option)
                                    "
                                    href="#"
                                    ref="size_option"
                                    class="option"
                                    :class="{
                                        selected: option.selected,
                                        disabled: option.disabled,
                                    }"
                                >
                                    <span
                                        v-if="option.best_seller"
                                        class="option-tag"
                                        >BEST SELLER</span
                                    >
                                    <p>{{ formatSize(option.size) }}</p>
                                    <p>
                                        {{ formatSizePrice(option) }}
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        class="frame-upgrade-section"
                        v-if="
                            availableFrameOptions.length > 0 &&
                            !order.copyPurchased
                        "
                    >
                        <div class="row">
                            <div class="col">
                                <h3>UPGRADE FRAME</h3>
                            </div>
                        </div>
                        <div class="row options">
                            <div
                                class="col"
                                v-for="option in availableFrameOptions"
                                :key="option.label"
                            >
                                <a
                                    @click.prevent="
                                        selectOption('frame_options', option)
                                    "
                                    href="#"
                                    ref="frame_option"
                                    class="option"
                                    :class="{
                                        selected: option.selected,
                                        disabled:
                                            option.disabled ||
                                            (option.label !== 'No frame' &&
                                                framesDisabled),
                                        'frame-color-black':
                                            option.label === 'black',
                                        'frame-color-walnut':
                                            option.label === 'walnut',
                                    }"
                                >
                                    <p>
                                        {{
                                            capitalizeFirstLetter(
                                                snakeCaseToSpace(option.label)
                                            )
                                        }}
                                    </p>
                                    <p>
                                        {{ formatFramePrice(option) }}
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <WarrantyUpgrade
                        :order="order"
                        @warrantyUpdate="(a) => (warranty_cost = a)"
                        v-if="
                            findSelectedOrCurrent('size_options')?.label !==
                            'digital only'
                        "
                    />

                    <CanvasUpgrade
                        v-if="
                            findSelectedOrCurrent('frame_options')?.label !==
                                'No frame' && order.canvas !== 'thick'
                        "
                        :order="order"
                        @canvasUpdate="(a) => (canvas_cost = a)"
                    />

                    <GetACopy
                        :order="order"
                        @additionalCopyUpdate="handleAdditionalCopyUpdate"
                        v-if="
                            findSelectedOrCurrent('size_options')?.label !==
                                'digital only' &&
                            findSelectedOrCurrent('frame_options')?.label ===
                                'No frame'
                        "
                        ref="additional_copy_options"
                    />

                    <ShippingUpgrade
                        :order="order"
                        @shippingUpgradeUpdate="(a) => (shipping_cost = a)"
                        v-if="
                            orderStore.order_data?.customer_data?.orders
                                ?.length === 1
                        "
                        ref="shipping_upgrade_options"
                    />

                    <div v-if="hasAdditionalUpgrades && false">
                        <hr />
                        <h3>
                            Additional upgrades
                            <a
                                href="#"
                                class="show-additional-upgrades"
                                @click.prevent="
                                    show_additional_upgrades =
                                        !show_additional_upgrades
                                "
                                >SHOW
                                <span v-if="!show_additional_upgrades"
                                    >MORE</span
                                ><span v-else>LESS</span></a
                            >
                        </h3>
                        <div v-if="show_additional_upgrades">
                            <WarrantyUpgrade
                                :order="order"
                                @warrantyUpdate="(a) => (warranty_cost = a)"
                                ref="warranty_options"
                            />
                            <MugUpgrade
                                :order="order"
                                @mugUpdate="(a) => (mug_cost = a)"
                                ref="mug_options"
                            />
                        </div>
                    </div>
                    <div
                        class="row upgrade-submit"
                        v-if="upgradeOptionsAreAvailable"
                    >
                        <div class="col">
                            <a
                                @click.prevent="handleUpgrade"
                                href="#"
                                class="btn btn-tmr-gold"
                            >
                                UPGRADE -
                                {{ currencySymbol(currency) + sumTotal }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { toRaw } from "vue";
import { useOrderStore } from "@/stores/order";
import { useCartStore } from "@/stores/cart";
import { useAuthStore } from "@/stores/auth";
import { currencySymbol } from "@/utilities/currency";
import {
    capitalize,
    capitalizeFirstLetter,
    snakeCaseToSpace,
} from "@/utilities/strings";
import * as Sentry from "@sentry/vue";
import {
    sizeToLandscape,
    sizeToPortrait,
} from "@/utilities/lineItemUtils";
import WarrantyUpgrade from "@/components/upgrade-controls/components/WarrantyUpgrade.vue";
import MugUpgrade from "@/components/upgrade-controls/components/MugUpgrade.vue";
import GetACopy from "@/components/upgrade-controls/components/GetACopy.vue";
import InfoPopoverComponent from "@/components/InfoPopoverComponent.vue";
import ShippingUpgrade from "@/components/upgrade-controls/components/ShippingUpgrade.vue";
import CanvasUpgrade from "@/components/upgrade-controls/components/CanvasUpgrade.vue";

export default {
    name: "LineItemUpgradeSection",
    components: {
        ShippingUpgrade,
        CanvasUpgrade,
        GetACopy,
        MugUpgrade,
        WarrantyUpgrade,
        InfoPopoverComponent,
    },
    props: {
        order: Object,
    },
    data() {
        return {
            orderStore: useOrderStore(),
            cartStore: useCartStore(),
            authStore: useAuthStore(),
            currency: "",
            size_options: [],
            frame_options: [],
            cart: {},
            canvas_cost: 0,
            warranty_cost: 0,
            additional_copy_cost: 0,
            mug_cost: 0,
            shipping_cost: 0,
            show_additional_upgrades: false,
            updating: false,
        };
    },
    computed: {
        sumTotal() {
            let total = 0;
            let qty = this.order.quantity || 1;

            if (this.$refs.size_option) {
                this.size_options.forEach((elm) => {
                    if (elm.selected) {
                        let costOfOne = this.getSizeUpgradeValue(elm) || 0;
                        total += costOfOne * qty;
                    }
                });
            }

            let frameOption = this.findSelectedOrCurrent("frame_options");

            let costOfOne = frameOption
                ? this.getFrameUpgradeValue(frameOption)
                : 0;

            total += costOfOne * qty;

            let canvasOption = this.findSelectedOrCurrent("canvas_options");

            costOfOne = canvasOption
                ? this.getCanvasUpgradeValue(canvasOption)
                : 0;

            total += costOfOne * qty;

            total += this.canvas_cost;

            total += this.warranty_cost;

            total += this.additional_copy_cost;

            total += this.mug_cost;

            total += this.shipping_cost;

            return total.toFixed(2);
        },
        framesDisabled() {
            return this.additional_copy_cost > 0;
        },
        currentFrame() {
            let order_frame = this.order.frame ? this.order.frame.trim() : "";
            if (order_frame) {
                return order_frame.toLowerCase();
            }

            let shopify_frame = this.orderStore.order_data.customer_data
                .shopify_frame
                ? this.orderStore.order_data.customer_data.shopify_frame.trim()
                : "";

            if (shopify_frame) {
                return shopify_frame.toLowerCase();
            }

            return "";
        },
        hasAdditionalUpgrades() {
            return (
                !!this.orderStore.order_data?.available_additional_upgrades
                    ?.warranty?.length ||
                !!this.orderStore.order_data?.available_additional_upgrades?.mug
                    ?.length ||
                !!this.orderStore.order_data?.available_additional_upgrades
                    ?.phone_case?.length
            );
        },
        availableFrameOptions() {
            let options = this.frame_options.filter((o) => o.available);

            return options.length > 1 ? options : [];
        },
    },
    asyncComputed: {
        async upgradeOptionsAreAvailable() {
            await this.$nextTick();

            return (
                this.$refs.frame_option ||
                this.$refs.size_option ||
                this.$refs.canvas_option ||
                this.$refs.warranty_option ||
                this.$refs.additional_copy_option ||
                this.$refs.shipping_upgrade_option ||
                false
            );
        },
    },
    mounted() {
        this.currency = this.orderStore.order_data.order_currency;

        this.setupOptions();
    },
    methods: {
        capitalizeFirstLetter,
        currencySymbol,
        capitalize,
        snakeCaseToSpace,
        setupOptions() {
            if (this.order.allowUpsells === false) {
                return false;
            }
            // sizes
            let availableSizeUpgrades =
                this.orderStore.order_data.available_upgrades.size_upgrades;

            if (availableSizeUpgrades) {
                for (let i = 0; i < availableSizeUpgrades.length; i++) {
                    let elm = Object.assign(
                        {},
                        availableSizeUpgrades[
                            availableSizeUpgrades.length - i - 1
                        ]
                    );

                    elm.current = false;
                    elm.selected = false;
                    elm.label = elm.size;

                    if (elm.size === sizeToPortrait(this.order.orderSize)) {
                        elm.current = true;
                        elm.selected = true;
                        this.size_options.unshift(elm);
                        break;
                    }

                    this.size_options.unshift(elm);
                }
            }

            // frames
            let currentFrame = this.order.frame
                ? this.order.frame.trim()
                : this.orderStore.order_data.customer_data.shopify_frame;

            let frameUpgrades = Object.assign(
                {},
                this.orderStore.order_data.available_upgrades.frame
            );

            if (Object.values(frameUpgrades).flat(Infinity).length > 0) {
                let frame_options = [];

                if (!currentFrame) {
                    frame_options[0] = {
                        label: "No frame",
                        amount: 0,
                        currency: this.orderStore.order_data.order_currency,
                        selected: true,
                        disabled: false,
                        current: true,
                        available: true,
                    };
                }

                let referentSize = sizeToPortrait(this.order.orderSize);

                Object.keys(frameUpgrades).forEach((key) => {
                    // if (!frameUpgrades[key][referentSize]) {
                    //     return;
                    // }

                    let newOption = {
                        label: key,
                        ...frameUpgrades[key][frameUpgrades[key].keys?.[0]],
                    };

                    newOption.amount =
                        frameUpgrades[key][referentSize]?.amount || 0;

                    newOption.selected = currentFrame === newOption.label;

                    newOption.current = currentFrame === newOption.label;

                    newOption.available = !!frameUpgrades[key][referentSize];

                    newOption.currency =
                        this.orderStore.order_data.order_currency;

                    frame_options.push(newOption);
                });

                this.frame_options = frame_options;
            }
        },
        updateFrameOptions() {
            // frame
            let frameUpgrades =
                this.orderStore.order_data.available_upgrades.frame;

            let referentSize =
                this.findSelectedOrCurrent("size_options")?.size ||
                sizeToPortrait(this.order.orderSize);

            let hasSelected = false;
            for (let i = 0; i < this.frame_options.length; i++) {
                if (this.frame_options[i].label === "No frame") {
                    continue;
                }

                this.frame_options[i].currency =
                    frameUpgrades[this.frame_options[i].label][referentSize]
                        ?.currency || "";
                this.frame_options[i].amount =
                    frameUpgrades[this.frame_options[i].label][referentSize]
                        ?.amount || 0;
                this.frame_options[i].available =
                    !!frameUpgrades[this.frame_options[i].label][referentSize];
                this.frame_options[i].selected = frameUpgrades[
                    this.frame_options[i].label
                ][referentSize]
                    ? this.frame_options[i].selected
                    : false;

                hasSelected ||= this.frame_options[i].selected;
            }

            if (!hasSelected && this.frame_options[0]) {
                this.frame_options[0].selected = true;
            }
        },
        handleAdditionalCopyUpdate(e) {
            this.additional_copy_cost = e;

            if (e > 0) {
                this.selectOption("frame_options", this.frame_options[0]);
            }
        },
        formatSizePrice(option) {
            let value = this.getSizeUpgradeValue(option);

            if (option.current) {
                return "Current";
            }

            return (
                "+" +
                currencySymbol(option.currency) +
                value * this.order.quantity
            );
        },
        formatFramePrice(option) {
            let value = this.getFrameUpgradeValue(option);

            if (option.current) {
                return "Current";
            }

            return (
                "+" +
                currencySymbol(option.currency) +
                value * this.order.quantity
            );
        },
        getSizeUpgradeValue(option) {
            let current = this.findCurrent("size_options");

            return option.amount - (current?.amount || 0);
        },
        getFrameUpgradeValue(option) {
            let currentSize =
                this.findCurrent("size_options")?.size ||
                sizeToPortrait(this.order.orderSize);

            let currentFrame =
                (this.order.frame ? this.order.frame.trim() : "") ||
                this.orderStore.order_data.customer_data.shopify_frame ||
                null;

            if (currentFrame) {
                currentFrame = currentFrame.toLowerCase();
            }

            let currentFramePrice = currentFrame
                ? this.orderStore.order_data.available_upgrades.frame[
                    currentFrame
                ][currentSize]?.amount || 0
                : 0;

            let upgradeValue = (option.amount || 0) - currentFramePrice;

            if (upgradeValue < 0) {
                return 0;
            }

            return upgradeValue;
        },
        findCurrent(collection) {
            return this[collection]?.find((elm) => elm.current);
        },
        findSelected(collection) {
            return this[collection]?.find((elm) => elm.selected);
        },
        findSelectedOrCurrent(collection) {
            let selected = this[collection]?.find((elm) => elm.selected);

            if (selected) {
                return selected;
            }

            return this[collection]?.find((elm) => elm.current);
        },
        selectOption(collection, option) {
            if (collection === "frame_options") {
                if (this.framesDisabled) {
                    return;
                }
            }

            this[collection].forEach((elm) => {
                elm.selected = elm.label === option.label;
            });

            // FIXME: Hacky, refactor later
            if (collection === "frame_options") {
                this.emitter.emit("frame-changed", option.label);
            }

            if (
                collection === "size_options" &&
                option.label === "digital only"
            ) {
                this.emitter.emit("frame-changed", "");
            }
            // END

            this.updateFrameOptions();
        },
        handleUpgrade() {
            if (this.updating) {
                return false;
            }

            this.updating = true;

            let currentSize = toRaw(this.findCurrent("size_options"));
            let currentFrame = toRaw(this.findCurrent("frame_options"));
            let selectedSize = toRaw(this.findSelected("size_options"));
            let selectedFrame = toRaw(this.findSelected("frame_options"));

            let cart = {};

            if (selectedSize && selectedSize.label !== currentSize.label) {
                cart["size"] = Object.assign({}, selectedSize);
                cart["size"].amount = this.getSizeUpgradeValue(selectedSize);
            }

            if (
                selectedFrame &&
                (selectedFrame.label !== currentFrame.label ||
                    this.getFrameUpgradeValue(selectedFrame) > 0)
            ) {
                cart["frame"] = Object.assign({}, selectedFrame);
                cart["frame"].amount = this.getFrameUpgradeValue(selectedFrame);
            }

            if (
                !cart["size"] &&
                !cart["frame"] &&
                !cart["canvas"] &&
                !cart["shipping_upgrade"] &&
                this.cartStore.isCartEmpty()
            ) {
                this.updating = false;
                return false;
            }

            this.cartStore.addToCart(this.order, cart);

            // return;

            if (this.cartStore.getAmount() === 0) {
                this.$Progress.start();

                return this.cartStore
                    .handleCheckout()
                    .then(() => {
                        if (cart["frame"]) {
                            this.orderStore.updateFrame(
                                this.order.id,
                                cart["frame"]["label"]
                            );
                        }

                        if (cart["size"]) {
                            this.orderStore.updateSize(
                                this.order.id,
                                cart["size"]["label"]
                            );
                        }

                        this.cartStore.clearCart();
                        this.setSelectedAsCurrent();
                        this.updateFrameOptions();
                        this.$Progress.finish();
                        this.$toast.add({
                            severity: "success",
                            summary: "Success",
                            detail: "Your order has been updated!",
                            life: 3500,
                        });
                    })
                    .catch((e) => {
                        this.$Progress.fail();

                        this.$toast.add({
                            severity: "error",
                            summary: "Error",
                            detail:
                                e?.response?.data ||
                                "There was a problem with your request. Please try again later.",
                            life: 5000,
                        });

                        if (e?.response?.status >= 500) {
                            Sentry.captureException(e);
                        } else if (
                            e?.response?.status >= 400 &&
                            e?.response?.status < 500
                        ) {
                            this.authStore.logOut().then(() => {
                                this.$router.push({
                                    name: "login",
                                    query: {
                                        redirect: this.$route.fullPath,
                                    },
                                });
                            });
                        }
                    })
                    .finally(() => {
                        this.updating = false;
                    });
            } else {
                this.$Progress.start();
                return this.cartStore
                    .handleCheckout()
                    .then((res) => {
                        this.$Progress.finish();
                        window.location.replace(res.data);
                    })
                    .catch((e) => {
                        this.$Progress.fail();

                        this.$toast.add({
                            severity: "error",
                            summary: "Error",
                            detail:
                                e?.response?.data ||
                                "There was a problem with your request. Please try again later.",
                            life: 5000,
                        });
                    })
                    .finally(() => {
                        this.updating = false;
                    });
            }
        },
        setSelectedAsCurrent() {
            this.frame_options.forEach((elm) => {
                elm.current = elm.selected;
            });

            this.size_options.forEach((elm) => {
                elm.current = elm.selected;
            });
        },
        formatSize(size) {
            if (size === "digital only") {
                return "Digital";
            }

            if (this.order.orientation === "horizontal") {
                return sizeToLandscape(size);
            }

            return size;
        },
    },
};
</script>

<style lang="scss">
// preload images
html {
    background-image: url("@/assets/images/upsells/sizes/physical_canvas.jpg");
    background-size: 0;
}
</style>

<style scoped lang="scss">
.line-item-upgrade-section {
    width: 100%;
    text-align: left;
    margin-top: 8px;
    font-family: Helvetica, Inter, Roboto, Arimo, "Nimbus Sans", sans-serif;

    .col {
        padding-left: 4px;
        padding-right: 4px;
    }

    h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .subtitle {
        font-size: 14px;
        line-height: 20px;
        color: #8d8b88;
    }

    .upgrade-controls {
        padding: 0 0 24px 0;
        margin: 17px 0 5px;

        h3 {
            margin: 16px 0 8px;
            font-size: 14px;
            line-height: 20px;
            color: #363636;
        }

        a.option {
            text-align: center;
            color: #392f5a;
            background-color: #ebeaee;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            display: block;
            padding: 8px;
            outline: 1px solid #dad8e0;
            border-radius: 4px;

            &:hover {
                text-decoration: none;
            }

            &.selected {
                outline: 2px solid #392f5a;
            }

            &.disabled {
                cursor: default;
                //background: lighten(#ebeaee, 10%);
                //outline-color: lighten(#8d8b88, 2%);
                //color: lighten(#9084b5, 2%);
                opacity: 0.3;
            }

            &.frame-color-black {
                background-image: url("@/assets/images/upsells/frames/black.png");
                background-size: 50%;
                background-position: right center;
                background-repeat: no-repeat;
            }

            &.frame-color-walnut {
                background-image: url("@/assets/images/upsells/frames/walnut.png");
                background-size: 50%;
                background-position: right center;
                background-repeat: no-repeat;
            }

            span.option-tag {
                position: absolute;
                margin-top: -15px;
                width: 60px;
                margin-left: calc((100% - 85px) / 2);
                z-index: 999;
                font-size: 8px;
                line-height: 14px;
                display: block;
                background-color: #392f5a;
                color: #fff;
                border-radius: 2px;
                padding: 0px 1px;
                text-align: center;
            }

            p {
                margin: 5px 0;
            }
        }

        a.show-additional-upgrades {
            color: #363636;
            float: right;
            font-size: 0.8em;
            line-height: 2em;
            vertical-align: middle;
        }

        .upgrade-submit {
            margin-top: 26px;

            a.btn {
                width: 100%;
                font-weight: bold;
            }
        }
    }
}
</style>
